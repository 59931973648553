<template>
	<article class="lp" id="js_pagetop">
		<h1 class="lp__header"><img src="~@/assets/img/lp_heatshock2023/title.png" alt="寒い日は要注意！ヒートショック―予防と対策― presented by 頭痛ーる"></h1>

		<!-- イントロ -->
		<p class="lp__intro">
			冬の寒さや室内での急激な温度変化がきっかけで血圧が大きく上下し、具合が悪くなる<em>「ヒートショック」</em>。めまいや立ちくらみの他、失神したり、心筋梗塞や脳卒中を起こす可能性もあります。冬から春先まで発症数が多いヒートショック。まずは予防のポイントをクイズで楽しみながらチェックして、頭痛ーるの週間ヒートショックやピンポイント気温予報を活用して健康的に過ごしましょう！
		</p>
		<!-- /イントロ -->

		<!-- 目次 -->
		<nav class="lp__nav">
			<h2 class="lp__nav--title">ヒートショック予防メニュー</h2>
			<ul class="lp__nav--list">
				<li>
					<a href="javascript:void(0)" @click="onScroll('js_quiz')">
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30.761 27.041" class="is-left">
							<g>
								<path d="M15.38,27.041s9.557-8.384,13.531-13.806C31.5,9.7,31.466,4.886,28.189,1.982a7.856,7.856,0,0,0-11.151.728l-1.658,1.9-1.658-1.9A7.855,7.855,0,0,0,2.57,1.982C-.706,4.886-.744,9.7,1.849,13.235,5.823,18.657,15.38,27.041,15.38,27.041" transform="translate(0.001 0)" fill="#ff3515"/>
								<path d="M119.324,70.983l5.387.926L112.024,81.6l3.067-6.651-3.727-.641L115.282,63.8l6.815,1.171Z" transform="translate(-100.551 -58.547)" fill="#fff"/>
							</g>
						</svg>
						<span class="is-center">クイズで学ぶ！ヒートショック</span>
						<svg class="is-right" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 18">
							<path d="M18.232,15.193,11.614,8.387a1.307,1.307,0,0,1,0-1.817,1.235,1.235,0,0,1,1.77,0l7.5,7.712a1.311,1.311,0,0,1,.036,1.774l-7.53,7.765a1.232,1.232,0,0,1-1.77,0,1.307,1.307,0,0,1,0-1.817Z" transform="translate(-11.246 -6.196)" fill="#ff3515"/>
						</svg>
					</a>
				</li>
				<li>
					<a href="javascript:void(0)" @click="onScroll('js_explanation')">
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30.761 27.041" class="is-left">
							<g>
								<path d="M15.38,27.041s9.557-8.384,13.531-13.806C31.5,9.7,31.466,4.886,28.189,1.982a7.856,7.856,0,0,0-11.151.728l-1.658,1.9-1.658-1.9A7.855,7.855,0,0,0,2.57,1.982C-.706,4.886-.744,9.7,1.849,13.235,5.823,18.657,15.38,27.041,15.38,27.041" transform="translate(0.001 0)" fill="#ff3515"/>
								<path d="M119.324,70.983l5.387.926L112.024,81.6l3.067-6.651-3.727-.641L115.282,63.8l6.815,1.171Z" transform="translate(-100.551 -58.547)" fill="#fff"/>
							</g>
						</svg>
						<span class="is-center">ヒートショック解説・予防法</span>
						<svg class="is-right" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 18">
							<path d="M18.232,15.193,11.614,8.387a1.307,1.307,0,0,1,0-1.817,1.235,1.235,0,0,1,1.77,0l7.5,7.712a1.311,1.311,0,0,1,.036,1.774l-7.53,7.765a1.232,1.232,0,0,1-1.77,0,1.307,1.307,0,0,1,0-1.817Z" transform="translate(-11.246 -6.196)" fill="#ff3515"/>
						</svg>
					</a>
				</li>
				<li>
					<a href="javascript:void(0)" @click="onScroll('js_risk')">
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30.761 27.041" class="is-left">
							<g>
								<path d="M15.38,27.041s9.557-8.384,13.531-13.806C31.5,9.7,31.466,4.886,28.189,1.982a7.856,7.856,0,0,0-11.151.728l-1.658,1.9-1.658-1.9A7.855,7.855,0,0,0,2.57,1.982C-.706,4.886-.744,9.7,1.849,13.235,5.823,18.657,15.38,27.041,15.38,27.041" transform="translate(0.001 0)" fill="#ff3515"/>
								<path d="M119.324,70.983l5.387.926L112.024,81.6l3.067-6.651-3.727-.641L115.282,63.8l6.815,1.171Z" transform="translate(-100.551 -58.547)" fill="#fff"/>
							</g>
						</svg>
						<span class="is-center">毎週更新！ヒートショックの<br>危険度</span>
						<svg class="is-right" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 18">
							<path d="M18.232,15.193,11.614,8.387a1.307,1.307,0,0,1,0-1.817,1.235,1.235,0,0,1,1.77,0l7.5,7.712a1.311,1.311,0,0,1,.036,1.774l-7.53,7.765a1.232,1.232,0,0,1-1.77,0,1.307,1.307,0,0,1,0-1.817Z" transform="translate(-11.246 -6.196)" fill="#ff3515"/>
						</svg>
					</a>
				</li>
			</ul>
		</nav>
		<!-- /目次 -->

		<!-- クイズ -->
		<section class="lp__contents is-quiz" id="js_quiz">
			<h2 class="lp__contents--title"><img src="~@/assets/img/lp_heatshock2023/top_subtitle01.png" alt="ヒートショッククイズ どんな時？どんな人？どう気づく？"></h2>
			<p class="lp__text lp__contents--text">ヒートショックのことをクイズで楽しく学びましょう。注意するのは入浴時だけじゃない意外な場面も！ふくろう博士が予防法も詳しく解説しています。</p>

			<!-- Q1 -->
			<section class="lp__quiz">
				<h3 class="lp__quiz--title">どんな「時」が危険?</h3>
				<p class="lp__quiz--text">ヒートショックの危険度が高いのはどんな時？当てはまるのを選択してください。（複数選択可）</p>
				<ul class="lp__quiz--list">
					<li @click="onClickQuiz(0, 0)" :class="{ 'is-check': arrayQuiz[0][0] }">
						<em>A</em>
						<p>冷え込みが強い時</p>
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
							<g transform="translate(-55 -63)">
								<circle cx="16" cy="16" r="16" transform="translate(55 63)" fill="#fff"/>
								<path d="M7.472,20.659.322,13.51a1.1,1.1,0,0,1,0-1.556L1.878,10.4a1.1,1.1,0,0,1,1.556,0l4.816,4.816L18.565,4.9a1.1,1.1,0,0,1,1.556,0l1.555,1.556a1.1,1.1,0,0,1,0,1.556L9.027,20.659A1.1,1.1,0,0,1,7.472,20.659Z" transform="translate(60 66.22)" fill="#ff3515"/>
							</g>
						</svg>
					</li>
					<li @click="onClickQuiz(0, 1)" :class="{ 'is-check': arrayQuiz[0][1] }">
						<em>B</em>
						<p>気温差が大きい時</p>
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
							<g transform="translate(-55 -63)">
								<circle cx="16" cy="16" r="16" transform="translate(55 63)" fill="#fff"/>
								<path d="M7.472,20.659.322,13.51a1.1,1.1,0,0,1,0-1.556L1.878,10.4a1.1,1.1,0,0,1,1.556,0l4.816,4.816L18.565,4.9a1.1,1.1,0,0,1,1.556,0l1.555,1.556a1.1,1.1,0,0,1,0,1.556L9.027,20.659A1.1,1.1,0,0,1,7.472,20.659Z" transform="translate(60 66.22)" fill="#ff3515"/>
							</g>
						</svg>
					</li>
					<li @click="onClickQuiz(0, 2)" :class="{ 'is-check': arrayQuiz[0][2] }">
						<em>C</em>
						<p>寒い部屋に移動した時</p>
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
							<g transform="translate(-55 -63)">
								<circle cx="16" cy="16" r="16" transform="translate(55 63)" fill="#fff"/>
								<path d="M7.472,20.659.322,13.51a1.1,1.1,0,0,1,0-1.556L1.878,10.4a1.1,1.1,0,0,1,1.556,0l4.816,4.816L18.565,4.9a1.1,1.1,0,0,1,1.556,0l1.555,1.556a1.1,1.1,0,0,1,0,1.556L9.027,20.659A1.1,1.1,0,0,1,7.472,20.659Z" transform="translate(60 66.22)" fill="#ff3515"/>
							</g>
						</svg>
					</li>
					<li @click="onClickQuiz(0, 3)" :class="{ 'is-check': arrayQuiz[0][3] }">
						<em>D</em>
						<p>冷たい水に触れた時</p>
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
							<g transform="translate(-55 -63)">
								<circle cx="16" cy="16" r="16" transform="translate(55 63)" fill="#fff"/>
								<path d="M7.472,20.659.322,13.51a1.1,1.1,0,0,1,0-1.556L1.878,10.4a1.1,1.1,0,0,1,1.556,0l4.816,4.816L18.565,4.9a1.1,1.1,0,0,1,1.556,0l1.555,1.556a1.1,1.1,0,0,1,0,1.556L9.027,20.659A1.1,1.1,0,0,1,7.472,20.659Z" transform="translate(60 66.22)" fill="#ff3515"/>
							</g>
						</svg>
					</li>
				</ul>
				<a
					href="javascript:void(0);"
					@click="clickModalLink(1, $event)"
					data-gtm-event-cat-label="ヒートショック特集ページ2023クイズ回答1"
					data-gtm-event-act-label="trackingLpHeatShock2023Quiz1"
					:class="[
						'lp__quiz--button',
						'gtm-link_click_count01',
						{ 'is-active': arrayQuiz[0].includes(true) }
					]"
				>回答する</a>
			</section>
			<!-- /Q1 -->

			<!-- Q2 -->
			<section class="lp__quiz">
				<h3 class="lp__quiz--title">どんな「人」が危険?</h3>
				<p class="lp__quiz--text">ヒートショックは高齢者、高血圧や糖尿病などの人、肥満の人などが影響を受けやすいですが、その他に危険度が高いのはどんな人?次の中からお選びください。（複数選択可）</p>
				<ul class="lp__quiz--list">
					<li @click="onClickQuiz(1, 0)" :class="{ 'is-check': arrayQuiz[1][0] }">
						<em>A</em>
						<p>熱い風呂が好きな人</p>
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
							<g transform="translate(-55 -63)">
								<circle cx="16" cy="16" r="16" transform="translate(55 63)" fill="#fff"/>
								<path d="M7.472,20.659.322,13.51a1.1,1.1,0,0,1,0-1.556L1.878,10.4a1.1,1.1,0,0,1,1.556,0l4.816,4.816L18.565,4.9a1.1,1.1,0,0,1,1.556,0l1.555,1.556a1.1,1.1,0,0,1,0,1.556L9.027,20.659A1.1,1.1,0,0,1,7.472,20.659Z" transform="translate(60 66.22)" fill="#ff3515"/>
							</g>
						</svg>
					</li>
					<li @click="onClickQuiz(1, 1)" :class="{ 'is-check': arrayQuiz[1][1] }">
						<em>B</em>
						<p>あまり防寒しない人</p>
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
							<g transform="translate(-55 -63)">
								<circle cx="16" cy="16" r="16" transform="translate(55 63)" fill="#fff"/>
								<path d="M7.472,20.659.322,13.51a1.1,1.1,0,0,1,0-1.556L1.878,10.4a1.1,1.1,0,0,1,1.556,0l4.816,4.816L18.565,4.9a1.1,1.1,0,0,1,1.556,0l1.555,1.556a1.1,1.1,0,0,1,0,1.556L9.027,20.659A1.1,1.1,0,0,1,7.472,20.659Z" transform="translate(60 66.22)" fill="#ff3515"/>
							</g>
						</svg>
					</li>
					<li @click="onClickQuiz(1, 2)" :class="{ 'is-check': arrayQuiz[1][2] }">
						<em>C</em>
						<p>飲酒後に入浴する人</p>
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
							<g transform="translate(-55 -63)">
								<circle cx="16" cy="16" r="16" transform="translate(55 63)" fill="#fff"/>
								<path d="M7.472,20.659.322,13.51a1.1,1.1,0,0,1,0-1.556L1.878,10.4a1.1,1.1,0,0,1,1.556,0l4.816,4.816L18.565,4.9a1.1,1.1,0,0,1,1.556,0l1.555,1.556a1.1,1.1,0,0,1,0,1.556L9.027,20.659A1.1,1.1,0,0,1,7.472,20.659Z" transform="translate(60 66.22)" fill="#ff3515"/>
							</g>
						</svg>
					</li>
					<li @click="onClickQuiz(1, 3)" :class="{ 'is-check': arrayQuiz[1][3] }">
						<em>D</em>
						<p>朝風呂が好きな人</p>
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
							<g transform="translate(-55 -63)">
								<circle cx="16" cy="16" r="16" transform="translate(55 63)" fill="#fff"/>
								<path d="M7.472,20.659.322,13.51a1.1,1.1,0,0,1,0-1.556L1.878,10.4a1.1,1.1,0,0,1,1.556,0l4.816,4.816L18.565,4.9a1.1,1.1,0,0,1,1.556,0l1.555,1.556a1.1,1.1,0,0,1,0,1.556L9.027,20.659A1.1,1.1,0,0,1,7.472,20.659Z" transform="translate(60 66.22)" fill="#ff3515"/>
							</g>
						</svg>
					</li>
				</ul>
				<a
					href="javascript:void(0);"
					@click="clickModalLink(2, $event)"
					data-gtm-event-cat-label="ヒートショック特集ページ2023クイズ回答2"
					data-gtm-event-act-label="trackingLpHeatShock2023Quiz2"
					:class="[
						'lp__quiz--button',
						'gtm-link_click_count01',
						{ 'is-active': arrayQuiz[1].includes(true) }
					]"
				>回答する</a>
			</section>
			<!-- /Q2 -->

			<!-- Q3 -->
			<section class="lp__quiz">
				<h3 class="lp__quiz--title">危険な頭痛どう気付く?</h3>
				<p class="lp__quiz--text">突然いつもの頭痛とは全く違う痛みが!!冬の寒い時期に、危険な痛みや症状が出たら早い対処が必要です。特に気を付けるべき代表的症状は?次の中からお選びください。（複数選択可）</p>
				<ul class="lp__quiz--list">
					<li @click="onClickQuiz(2, 0)" :class="{ 'is-check': arrayQuiz[2][0] }">
						<em>A</em>
						<p>ハンマーで殴られたような頭痛</p>
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
							<g transform="translate(-55 -63)">
								<circle cx="16" cy="16" r="16" transform="translate(55 63)" fill="#fff"/>
								<path d="M7.472,20.659.322,13.51a1.1,1.1,0,0,1,0-1.556L1.878,10.4a1.1,1.1,0,0,1,1.556,0l4.816,4.816L18.565,4.9a1.1,1.1,0,0,1,1.556,0l1.555,1.556a1.1,1.1,0,0,1,0,1.556L9.027,20.659A1.1,1.1,0,0,1,7.472,20.659Z" transform="translate(60 66.22)" fill="#ff3515"/>
							</g>
						</svg>
					</li>
					<li @click="onClickQuiz(2, 1)" :class="{ 'is-check': arrayQuiz[2][1] }">
						<em>B</em>
						<p>朝起きた時の頭痛</p>
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
							<g transform="translate(-55 -63)">
								<circle cx="16" cy="16" r="16" transform="translate(55 63)" fill="#fff"/>
								<path d="M7.472,20.659.322,13.51a1.1,1.1,0,0,1,0-1.556L1.878,10.4a1.1,1.1,0,0,1,1.556,0l4.816,4.816L18.565,4.9a1.1,1.1,0,0,1,1.556,0l1.555,1.556a1.1,1.1,0,0,1,0,1.556L9.027,20.659A1.1,1.1,0,0,1,7.472,20.659Z" transform="translate(60 66.22)" fill="#ff3515"/>
							</g>
						</svg>
					</li>
					<li @click="onClickQuiz(2, 2)" :class="{ 'is-check': arrayQuiz[2][2] }">
						<em>C</em>
						<p>頭痛＋めまい・ふらつき</p>
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
							<g transform="translate(-55 -63)">
								<circle cx="16" cy="16" r="16" transform="translate(55 63)" fill="#fff"/>
								<path d="M7.472,20.659.322,13.51a1.1,1.1,0,0,1,0-1.556L1.878,10.4a1.1,1.1,0,0,1,1.556,0l4.816,4.816L18.565,4.9a1.1,1.1,0,0,1,1.556,0l1.555,1.556a1.1,1.1,0,0,1,0,1.556L9.027,20.659A1.1,1.1,0,0,1,7.472,20.659Z" transform="translate(60 66.22)" fill="#ff3515"/>
							</g>
						</svg>
					</li>
					<li @click="onClickQuiz(2, 3)" :class="{ 'is-check': arrayQuiz[2][3] }">
						<em>D</em>
						<p>頭痛＋ろれつが回らない</p>
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
							<g transform="translate(-55 -63)">
								<circle cx="16" cy="16" r="16" transform="translate(55 63)" fill="#fff"/>
								<path d="M7.472,20.659.322,13.51a1.1,1.1,0,0,1,0-1.556L1.878,10.4a1.1,1.1,0,0,1,1.556,0l4.816,4.816L18.565,4.9a1.1,1.1,0,0,1,1.556,0l1.555,1.556a1.1,1.1,0,0,1,0,1.556L9.027,20.659A1.1,1.1,0,0,1,7.472,20.659Z" transform="translate(60 66.22)" fill="#ff3515"/>
							</g>
						</svg>
					</li>
				</ul>
				<a
					href="javascript:void(0);"
					@click="clickModalLink(3, $event)"
					data-gtm-event-cat-label="ヒートショック特集ページ2023クイズ回答3"
					data-gtm-event-act-label="trackingLpHeatShock2023Quiz3"
					:class="[
						'lp__quiz--button',
						'gtm-link_click_count01',
						{ 'is-active': arrayQuiz[2].includes(true) }
					]"
				>回答する</a>
			</section>
			<!-- /Q3 -->
		</section>
		<!-- /クイズ -->

		<!-- アプリ誘導 -->
		<section class="lp__app-info">
			<h2 class="lp__app-info--title"><span>頭痛ーるの天気予報が便利です!</span></h2>
			<p class="lp__app-info--text">頭痛ーるで<em>前日との気温差</em>や<em>朝晩の冷え込み</em>をチェックして<em class="is-primary-color">ヒートショックを予防</em>しよう</p>
			<p class="lp__app-info--img"><img src="~@/assets/img/lp_heatshock2023/app_info_img.png" alt="前日との気温差をチェック 気温を細かくチェック"></p>
			<a v-if="isAndroid" href="dcmdam://launch?url=http%3A%2F%2Fapp-manager.docomo.ne.jp%2FSpApps%2FdetailApp%3Fdcmstore_view%3Dnone%26cId%3D10000023012" class="lp__app-info--button gtm-link_click_count01" data-gtm-event-cat-label="ヒートショック特集ページ2023_アプリDLボタン_アプリ誘導1android" data-gtm-event-act-label="trackingLpHeatShock2023_appDownload_info1_android"><span>頭痛ーるアプリで</span><span class="is-large">天気予報をチェックする</span></a>
			<a v-else href="javascript:void(0);" @click.prevent="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=appDownload`, _backPage: 'top' } })" class="lp__app-info--button gtm-link_click_count01" data-gtm-event-cat-label="ヒートショック特集ページ2023_アプリDLボタン_アプリ誘導1iOS" data-gtm-event-act-label="trackingLpHeatShock2023_appDownload_info1_iOS"><span>頭痛ーるアプリで</span><span class="is-large">天気予報をチェックする</span></a>
		</section>
		<!-- /アプリ誘導 -->

		<!-- 解説・予防法 -->
		<section class="lp__contents is-explanation" id="js_explanation">
			<h2 class="lp__contents--title"><img src="~@/assets/img/lp_heatshock2023/top_subtitle02.png" alt="ヒートショック 解説・予防法"></h2>
			<p class="lp__text lp__contents--text">急激な温度変化による血圧の乱高下がヒートショックの主な原因。暖房費の高騰による節約志向の高まりで、家の中に寒いままの場所が多くなっているかもしれません。日々の生活で温度変化の影響(寒冷刺激)を出来るだけ小さくすることが予防の第一歩！</p>

			<!-- 入浴時に起きるヒートショック -->
			<section class="lp__explanation">
				<h3 class="lp__explanation--title"><span>入浴時に起きるヒートショック</span></h3>
				<p class="lp__explanation--text">暖かい部屋では血圧は安定していますが、寒い脱衣所に移動すると血管が縮んで血圧が急上昇。そして、暖かい湯に浸かると血管が広がり、血圧が急低下します。この温度変化に伴う血圧の急激な上下動がヒートショックの主な原因です。</p>
				<p class="lp__explanation--img"><img src="~@/assets/img/lp_heatshock2023/img01.png" alt="血圧の変化 暖かい室内 血圧安定 寒い脱衣所 血管が縮み血圧急上昇 熱い風呂 血管が広がり血圧急低下"></p>
			</section>
			<!-- /入浴時に起きるヒートショック -->

			<!-- 怖いヒートショック -->
			<section class="lp__explanation">
				<h3 class="lp__explanation--title"><span>怖いヒートショック</span></h3>
				<p class="lp__explanation--text">脱衣所など寒い場所に移動すると血圧が上昇し、脳卒中や心筋梗塞のリスクが高まります。一方で熱い湯に浸かる急激な血圧低下ではめまいや立ちくらみ、失神の可能性も。高齢者が風呂場で意識を失って溺死する人の数は、2021年は全国で4,750人で、交通事故死者数の2倍以上というデータがあります。</p>
				<section class="lp__explanation--inner">
					<h4 class="lp__explanation--subtitle">
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26.404 23.742">
							<g transform="translate(0.79)">
								<path d="M9.892,4.591a3,3,0,0,1,5.217,0l7.345,12.927A3,3,0,0,1,19.845,22H5.155a3,3,0,0,1-2.608-4.482Z" fill="#ffcc42"/>
								<g transform="translate(0.744 2.029)">
									<path d="M12.084,5.421,2.627,21.209a2.233,2.233,0,0,0,1.909,3.35H23.451a2.233,2.233,0,0,0,1.909-3.35L15.9,5.421a2.233,2.233,0,0,0-3.819,0Z" transform="translate(-2.328 -4.346)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
									<path d="M18,13.5v4.466" transform="translate(-6.334 -6.686)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
									<path d="M18,25.5h0" transform="translate(-6.334 -9.753)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
								</g>
							</g>
						</svg>
						<span>家庭の浴槽での高齢者の溺死数</span>
					</h4>
					<p class="lp__explanation--img"><img src="~@/assets/img/lp_heatshock2023/img02.png" alt="4,750人/年 2021年・厚生労働省データ 交通事故死亡者数(2021年) 2,150人/年 "></p>
				</section>
				<section class="lp__explanation--inner">
					<h4 class="lp__explanation--subtitle">
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26.404 23.742">
							<g transform="translate(0.79)">
								<path d="M9.892,4.591a3,3,0,0,1,5.217,0l7.345,12.927A3,3,0,0,1,19.845,22H5.155a3,3,0,0,1-2.608-4.482Z" fill="#ffcc42"/>
								<g transform="translate(0.744 2.029)">
									<path d="M12.084,5.421,2.627,21.209a2.233,2.233,0,0,0,1.909,3.35H23.451a2.233,2.233,0,0,0,1.909-3.35L15.9,5.421a2.233,2.233,0,0,0-3.819,0Z" transform="translate(-2.328 -4.346)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
									<path d="M18,13.5v4.466" transform="translate(-6.334 -6.686)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
									<path d="M18,25.5h0" transform="translate(-6.334 -9.753)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
								</g>
							</g>
						</svg>
						<span>お風呂以外でも注意！</span>
					</h4>
					<p class="lp__explanation--text">寒い日に冷たい水に触れて洗車をしたり、早起きして雪かきをした際に、血圧が急上昇して、心筋梗塞や脳出血で倒れるということもあります。また、今大人気のサウナでも、刺激を求めて、高温すぎるサウナや低温すぎる水風呂に入るのは危険な場合があります。</p>
				</section>
			</section>
			<!-- /怖いヒートショック -->

			<!-- ヒートショックを防ぐには -->
			<section class="lp__explanation">
				<h3 class="lp__explanation--title"><span>ヒートショックを防ぐには</span></h3>
				<p class="lp__explanation--text">室内の温度差を減らす工夫がまずは大切。<br>トイレや脱衣所など寒くなりやすい場所に行く時は一枚羽織ったり、小型のヒーターの活用もおすすめです。</p>
				<section class="lp__explanation--inner">
					<h4 class="lp__explanation--subtitle">寒い日の安全入浴法</h4>
					<p class="lp__explanation--img"><img src="~@/assets/img/lp_heatshock2023/img03.png" alt="入浴法イメージ"></p>
					<dl class="lp__explanation--list">
						<dt><span>&#9312;</span>湯温は41&#8451;以下に</dt>
						<dd>特に高齢者には<em>39&#8451;&#0177;1&#8451;が適温</em></dd>
						<dt><span>&#9313;</span>脱衣所や浴室の温度を上げる</dt>
						<dd><em>シャワーで高い所から湯をはる</em><br>高血圧の方や高齢者は<em>一番風呂を避ける</em>。</dd>
						<dt><span>&#9314;</span>夕方までに入浴、朝風呂は✖</dt>
						<dd>室温が低く、血圧が高くなりやすい朝風呂は避けて、<em>夕食前の午後2時~午後4時頃</em>の入浴がおすすめ。</dd>
						<dt><span>&#9315;</span>食事直後や飲酒後の入浴を控える</dt>
						<dd>食後や飲酒直後は、<em>消化不良や血圧の低下や不整脈が起こりやすく</em>、食前や飲酒前の入浴が良い。</dd>
						<dt><span>&#9316;</span>入浴前後に水分補給</dt>
						<dd><em>水分不足でドロドロ血</em>になると、入浴後の脳梗塞や心筋梗塞のリスクが高まります。</dd>
					</dl>
				</section>
			</section>
			<!-- /ヒートショックを防ぐには -->
		</section>
		<!-- /解説・予防法 -->

		<!-- アプリ誘導 -->
		<section class="lp__app-info">
			<h2 class="lp__app-info--title"><span>頭痛ーるの天気予報が便利です!</span></h2>
			<p class="lp__app-info--text">頭痛ーるで<em>前日との気温差</em>や<em>朝晩の冷え込み</em>をチェックして<em class="is-primary-color">ヒートショックを予防</em>しよう</p>
			<p class="lp__app-info--img"><img src="~@/assets/img/lp_heatshock2023/app_info_img.png" alt="前日との気温差をチェック 気温を細かくチェック"></p>
			<a v-if="isAndroid" href="dcmdam://launch?url=http%3A%2F%2Fapp-manager.docomo.ne.jp%2FSpApps%2FdetailApp%3Fdcmstore_view%3Dnone%26cId%3D10000023012" class="lp__app-info--button gtm-link_click_count01" data-gtm-event-cat-label="ヒートショック特集ページ2023_アプリDLボタン_アプリ誘導2Android" data-gtm-event-act-label="trackingLpHeatShock2023_appDownload_info2_android"><span>頭痛ーるアプリで</span><span class="is-large">天気予報をチェックする</span></a>
			<a v-else href="javascript:void(0);" @click.prevent="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=appDownload`, _backPage: 'top' } })" class="lp__app-info--button gtm-link_click_count01" data-gtm-event-cat-label="ヒートショック特集ページ2023_アプリDLボタン_アプリ誘導2iOS" data-gtm-event-act-label="trackingLpHeatShock2023_appDownload_info2_iOS"><span>頭痛ーるアプリで</span><span class="is-large">天気予報をチェックする</span></a>
		</section>
		<!-- /アプリ誘導 -->

		<!-- 毎週更新！ヒートショックの危険度 -->
		<section class="lp__contents is-risk" id="js_risk">
			<h2 class="lp__contents--title"><img src="~@/assets/img/lp_heatshock2023/top_subtitle03.png" alt="毎週更新！ヒートショックの危険度"></h2>
			<p class="lp__text lp__contents--text">1週間の気温予想をベースに気象予報士がヒートショック予防のポイントを解説<br>アプリをダウンロードすると、毎週月曜日に情報更新のお知らせが届き、左上の三本線のハンバーガーメニューからもヒートショックの危険度をご確認いただけます。</p>

			<section class="lp__risk">
				<h3 class="lp__risk--title"><img src="~@/assets/img/lp_heatshock2023/img04.png" alt="毎週更新！ヒートショックの危険度"></h3>
				<a href="javascript:void(0);" @click="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=columnDetail`, _detailId: 906 } })" class="lp__risk--button gtm-link_click_count01" data-gtm-event-cat-label="ヒートショック特集ページ2023危険度リンクボタン" data-gtm-event-act-label="trackingLpHeatShock2023Risk"><span>ヒートショックの危険度を</span><span class="is-large">いますぐチェックする</span></a>
			</section>
		</section>
		<!-- /毎週更新！ヒートショックの危険度 -->

		<!-- アウトロ -->
		<section class="lp__outro">
			<h2 class="lp__outro--title">with頭痛ーるアプリで<br>ココロも体も健康な冬に</h2>
			<div class="lp__outro--img"><img src="@/assets/img/lp_heatshock2023/outro.png" alt="マロとヒロシ"></div>
			<a href="javascript:void(0);" @click.prevent="onScroll('js_pagetop')" class="lp__outro--button is-pagetop">上部にもどる</a>
			<a href="javascript:void(0);" @click.prevent="$router.push({ name: 'Cpsite', query: { id: sugotokuContentsId } })" class="lp__outro--button is-back">頭痛ーるTOPへ</a>
		</section>
		<!-- /アウトロ -->

		<!-- 下に吸着するアプリダウンロードボタン -->
		<aside class="sticky-bottom-appdownload">
			<a v-if="isAndroid" href="dcmdam://launch?url=http%3A%2F%2Fapp-manager.docomo.ne.jp%2FSpApps%2FdetailApp%3Fdcmstore_view%3Dnone%26cId%3D10000023012" class="sticky-bottom-appdownload--button gtm-link_click_count01" data-gtm-event-cat-label="ヒートショック特集ページ2023_アプリDLボタン_ページ下部に吸着するボタンAndroid" data-gtm-event-act-label="trackingLpHeatShock2023_appDownload_sticky_android"><img src="~@/assets/img/sugotoku_app_info_btn03.png" alt="今すぐダウンロード！"></a>
			<a v-else href="javascript:void(0);" @click.prevent="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=appDownload`, _backPage: 'top' } })" class="sticky-bottom-appdownload--button gtm-link_click_count01" data-gtm-event-cat-label="ヒートショック特集ページ2023_アプリDLボタン_ページ下部に吸着するボタンiOS" data-gtm-event-act-label="trackingLpHeatShock2023_appDownload_sticky_iOS"><img src="~@/assets/img/sugotoku_app_info_btn03.png" alt="今すぐダウンロード！"></a>
		</aside>
		<!-- /下に吸着するアプリダウンロードボタン -->

		<!-- モーダル -->
		<v-ons-modal :visible="isVisibleModal" animation="fade" animationOptions="{duration: 0.2, timing: 'ease-in'}" class="lp__modal">
			<aside class="lp__modal--inner">
				<div v-show="modalType === 1" class="lp__modal--body">
					<!-- slider -->
					<div class="slider">
						<div class="slider__container swiper-container js_swiper">
							<ul class="swiper-wrapper">
								<li class="swiper-slide">
									<i class="sprite q1_1" />
								</li>
								<li class="swiper-slide">
									<i class="sprite q1_2" />
								</li>
								<li class="swiper-slide">
									<i class="sprite q1_3" />
								</li>
								<li class="swiper-slide">
									<i class="sprite q1_4" />
								</li>
								<li class="swiper-slide">
									<i class="sprite q1_5" />
								</li>
								<li class="swiper-slide">
									<a v-if="isAndroid" href="dcmdam://launch?url=http%3A%2F%2Fapp-manager.docomo.ne.jp%2FSpApps%2FdetailApp%3Fdcmstore_view%3Dnone%26cId%3D10000023012" class="gtm-link_click_count01" data-gtm-event-cat-label="ヒートショック特集ページ2023_アプリDLボタン_回答1Android" data-gtm-event-act-label="trackingLpHeatShock2023_appDownload_answer1_android"><i class="sprite app_dl_1" /></a>
									<a v-else href="javascript:void(0);" @click="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=appDownload`, _backPage: 'top' } })" class="gtm-link_click_count01" data-gtm-event-cat-label="ヒートショック特集ページ2023_アプリDLボタン_回答1iOS" data-gtm-event-act-label="trackingLpHeatShock2023_appDownload_answer1_iOS"><i class="sprite app_dl_1" /></a>
								</li>
							</ul>
						</div>
						<div class="slider__nav">
							<div class="swiper-button-prev js_swiper_prev"></div>
							<div class="swiper-button-next js_swiper_next"></div>
						</div>
						<div class="swiper-pagination-bullets js_swiper_pagination"></div>
					</div>
					<!-- /slider -->
				</div>
				<div v-show="modalType === 2" class="lp__modal--body">
					<!-- slider -->
					<div class="slider">
						<div class="slider__container swiper-container js_swiper">
							<ul class="swiper-wrapper">
								<li class="swiper-slide">
									<i class="sprite q2_1" />
								</li>
								<li class="swiper-slide">
									<i class="sprite q2_2" />
								</li>
								<li class="swiper-slide">
									<i class="sprite q2_3" />
								</li>
								<li class="swiper-slide">
									<i class="sprite q2_4" />
								</li>
								<li class="swiper-slide">
									<i class="sprite q2_5" />
								</li>
								<li class="swiper-slide">
									<a v-if="isAndroid" href="dcmdam://launch?url=http%3A%2F%2Fapp-manager.docomo.ne.jp%2FSpApps%2FdetailApp%3Fdcmstore_view%3Dnone%26cId%3D10000023012" class="gtm-link_click_count01" data-gtm-event-cat-label="ヒートショック特集ページ2023_アプリDLボタン_回答2android" data-gtm-event-act-label="trackingLpHeatShock2023_appDownload_answer2_android"><i class="sprite app_dl_1" /></a>
									<a v-else href="javascript:void(0);" @click="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=appDownload`, _backPage: 'top' } })" class="gtm-link_click_count01" data-gtm-event-cat-label="ヒートショック特集ページ2023_アプリDLボタン_回答2iOS" data-gtm-event-act-label="trackingLpHeatShock2023_appDownload_answer2_iOS"><i class="sprite app_dl_1" /></a>
								</li>
							</ul>
						</div>
						<div class="slider__nav">
							<div class="swiper-button-prev js_swiper_prev"></div>
							<div class="swiper-button-next js_swiper_next"></div>
						</div>
						<div class="swiper-pagination-bullets js_swiper_pagination"></div>
					</div>
					<!-- /slider -->
				</div>
				<div v-show="modalType === 3" class="lp__modal--body">
					<!-- slider -->
					<div class="slider">
						<div class="slider__container swiper-container js_swiper">
							<ul class="swiper-wrapper">
								<li class="swiper-slide">
									<i class="sprite q3_1" />
								</li>
								<li class="swiper-slide">
									<i class="sprite q3_2" />
								</li>
								<li class="swiper-slide">
									<i class="sprite q3_3" />
								</li>
								<li class="swiper-slide">
									<i class="sprite q3_4" />
								</li>
								<li class="swiper-slide">
									<i class="sprite q3_5" />
								</li>
								<li class="swiper-slide">
									<a v-if="isAndroid" href="dcmdam://launch?url=http%3A%2F%2Fapp-manager.docomo.ne.jp%2FSpApps%2FdetailApp%3Fdcmstore_view%3Dnone%26cId%3D10000023012" class="gtm-link_click_count01" data-gtm-event-cat-label="ヒートショック特集ページ2023_アプリDLボタン_回答3android" data-gtm-event-act-label="trackingLpHeatShock2023_appDownload_answer3_android"><i class="sprite app_dl_2" /></a>
									<a v-else href="javascript:void(0);" @click="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=appDownload`, _backPage: 'top' } })" class="gtm-link_click_count01" data-gtm-event-cat-label="ヒートショック特集ページ2023_アプリDLボタン_回答3iOS" data-gtm-event-act-label="trackingLpHeatShock2023_appDownload_answer3_iOS"><i class="sprite app_dl_2" /></a>
								</li>
							</ul>
						</div>
						<div class="slider__nav">
							<div class="swiper-button-prev js_swiper_prev"></div>
							<div class="swiper-button-next js_swiper_next"></div>
						</div>
						<div class="swiper-pagination-bullets js_swiper_pagination"></div>
					</div>
					<!-- /slider -->
				</div>
				<div class="lp__modal--button">
					<a @click="clickModalLink(0, $event)">
						<ons-icon icon="md-close" />表示を閉じる
					</a>
				</div>
			</aside>
		</v-ons-modal>
		<!-- /モーダル -->
	</article>
</template>

<script>
// Vuex
import { mapGetters } from 'vuex'

// ライブラリ
import cmnConst from '@/assets/js/constant.js'
import Swiper from 'swiper'

export default {
	name: 'LpHeatShock2023',
	components: {
	},
	data () {
		return {
			backendUrl: cmnConst.BACKEND_URL,
			sugotokuContentsId: cmnConst.SUGOTOKU_CONTENTS_ID,
			$html: null,
			arrayQuiz: [	// クイズ回答用の配列（false: 未チェック、true: チェック）
				[false, false, false, false],	// q1
				[false, false, false, false],	// q2
				[false, false, false, false]	// q3
			],
			isVisibleModal: false,
			modalType: 0
		}
	},
	computed: {
		// map Vuex getters
		...mapGetters('common', ['isAndroid'])
	},
	created () {
	},
	mounted () {
		// 横幅に合わせてフォントサイズや余白をレスポンシブに可変する対応
		// TODO: common.scssでルートフォントをvwで指定するようにしたい。もしできたらこちらの処理は不要なので削除。
		const propWidth = document.body.offsetWidth
		const style = propWidth <= 640 ? 'font-size: 16px; font-size: 4.267vw;' : 'font-size: 27px;'
		this.$html = document.getElementsByTagName('html')[0]
		this.$html.setAttribute('style', style)

		// ページ遷移後のアンカーリンク
		// NOTE: 今後統一する予定
		if (this.$route.hash) window.addEventListener('load', this.hashAnchorLink)

		this.onSwiper()
	},
	beforeDestroy () {
		window.removeEventListener('load', this.hashAnchorLink)
		this.$html.removeAttribute('style')
	},
	methods: {
		// ページスクロール用の関数
		onScroll (id) {
			const elem = document.getElementById(id)
			const options = {
				container: 'body',
				duration: 500,
				easing: 'ease-out',
				offset: 0,
				cancelable: false,
				onStart: false,
				onDone: false,
				onCancel: false,
				x: false,
				y: true
			}
			this.$scrollTo(elem, options)
		},

		// ページ遷移後のアンカーリンク
		// NOTE: 今後統一する予定
		hashAnchorLink () {
			const $elem = document.querySelector(this.$route.hash)
			if ($elem) $elem.scrollIntoView ? $elem.scrollIntoView({ behavior: 'smooth', block: 'start' }) : scrollTo(0, $elem.getBoundingClientRect().top)
		},

		// クイズのチェック状況を更新するメソッド
		onClickQuiz (no, index) {
			const array = this.arrayQuiz[no]
			array[index] = !array[index]
			this.$set(this.arrayQuiz, no, array)
		},

		// モーダルのリンククリックイベント
		clickModalLink (value, event) {
			event.preventDefault()
			this.isVisibleModal = value > 0
			if (this.isVisibleModal) this.modalType = value
		},

		// Swiperの処理
		onSwiper () {
			const swiperConstructor = () => {
				const $swiper = document.querySelectorAll('.js_swiper')
				const $next = document.querySelectorAll('.js_swiper_next')
				const $prev = document.querySelectorAll('.js_swiper_prev')
				const $pagination = document.querySelectorAll('.js_swiper_pagination')
				let initSwiper
				for (let i = 0, len = $swiper.length; i < len; i++) {
					$swiper[i].className += i
					$next[i].className += i
					$prev[i].className += i
					$pagination[i].className += i
					initSwiper = new Swiper('.js_swiper' + i, {
						slidesPerView: 1,
						spaceBetween: 10,
						observer: true,
						observeParents: true,
						navigation: {
							nextEl: '.js_swiper_next' + i,
							prevEl: '.js_swiper_prev' + i
						},
						pagination: {
							el: '.js_swiper_pagination' + i
						}
					})
				}
				return initSwiper
			}
			swiperConstructor()
		}
	}
}
</script>

<style lang="scss" scoped>
@import "~@/assets/sass/variable";
@import "~swiper/css/swiper.min.css";

$spacing-3: 0.187rem;
$spacing-7: 0.437rem;
$spacing-12: 0.75rem;
$spacing-13: 0.812rem;
$spacing-19: 1.187rem;
$spacing-22: 1.375rem;
$spacing-24: 1.5rem;
$spacing-32: 2rem;
$spacing-39: 2.437rem;
$spacing-40: 2.5rem;
$spacing-50: 3.125rem;
$spacing-68: 4.25rem;
$spacing-70: 4.375rem;
$spacing-100_5: 6.281rem;
$spacing-105: 6.562rem;

$lp-primary-color: #FF3515;
$lp-secondary-color: #FFD870;
$lp-app-info-button-color: #CB0034;
$lp-app-info-button-border-color: #A5102F;
$lp-risk-button-border-color: #7A1506;
$line-height-1_375: 1.375;

.lp {
	font-family: $lp-family-primary;
	max-width: 640px;
	margin: 0 auto;
	background-color: $background-primary;
	line-height: $line-height;
	color: $text-primary;
	font-size: $font-size-16;
	line-height: $line-height-1_375;

	ul, li { list-style: none; }

	em { font-style: normal; }

	img {
		width: 100%;
		display: block;
	}

	.is-primary-color { color: $lp-primary-color; }

	/* ページ下部に吸着するボタン */
	.sticky-bottom-appdownload {
		margin: $spacing-30 0 0 0;
		padding: $spacing-8 $spacing-14;
		box-shadow: 0px -2px 2px rgba(0, 0, 0, 0.1);
	}

	/* ヘッダー */
	&__header {
		margin: 0 auto;
		padding: $spacing-7 $spacing-14 0;
	}

	/* イントロ */
	&__intro {
		margin: $spacing-10 auto 0;
		padding: 0 $spacing-14;

		& > em { color: $lp-primary-color; }
	}

	/* ナビ */
	&__nav {
		margin: $spacing-20 auto 0;

		&--title {
			font-family: $lp-family-tertiary;
			padding: $spacing-10 0;
			margin: 0;
			background-color: $lp-primary-color;
			font-size: $font-size-20;
			text-align: center;
			color: $text-seconday;
			line-height: $line-height-1;
		}

		&--list {
			padding: $spacing-22 0;
			margin: 0 auto;
			background-color: $lp-secondary-color;

			li {
				display: block;
				width: 86.1334%;
				margin: 0 auto;
				background-color: $background-primary;
				border: 4px solid $lp-primary-color;
				border-radius: $spacing-30;
				box-sizing: border-box;
				box-shadow: 0px 0px 6px 1px $lp-primary-color;

				& + li { margin-top: $spacing-20; }
				& > a {
					font-family: $family-primary;
					display: flex;
					align-items: center;
					padding: $spacing-13 $spacing-18 $spacing-12 $spacing-14;
					text-decoration: none;
					color: $_black;

					& > span, svg { display: block; }
					& > .is-left { width: $spacing-32; }
					& > .is-center {
						flex: 1;
						padding-left: $spacing-7;
					}
					& > .is-right { width: 3.534%; }
				}
				&:last-of-type > a { padding: $spacing-4 $spacing-18 $spacing-4 $spacing-14; }
			}
		}
	}

	/* セクション共通 */
	&__contents {
		$parent: &;
		padding: 0 $spacing-14;
		margin: $spacing-50 auto 0;
		box-sizing: border-box;

		&--title { margin: 0 auto; }
		&--text { margin: $spacing-16 auto 0; }

		&:first-of-type { margin: $spacing-40 auto 0; }
		&.is-quiz #{$parent}--title { width: 73.6312% }
		&.is-explanation #{$parent}--title { width: 52.45% }
		&.is-risk #{$parent}--title { width: 82.421% }
	}

	/* クイズ */
	&__quiz {
		position: relative;
		padding: $spacing-68 0 $spacing-32;
		margin: $spacing-30 $spacing-6 0;

		&:first-of-type { margin: $spacing-20 $spacing-6 0; }

		@for $i from 1 through 3 {
			&:nth-of-type(#{$i}) {
				background: url(~@/assets/img/lp_heatshock2023/quiz_bg_top0#{$i}.png) no-repeat 0 0, url(~@/assets/img/lp_heatshock2023/bg_bottom.png) no-repeat 0 100%;
				background-size: 100% auto, 100% auto;
			}
		}

		&--title {
			font-family: $lp-family-tertiary;
			display: inline-block;
			position: absolute;
			top: $spacing-39;
			left: $spacing-105;
			margin: 0;
			font-size: $font-size-20;
			color: $_black;
			line-height: $line-height-1;
		}

		&--text {
			width: 88.06%;
			margin: $spacing-4 auto 0;
		}

		&--list {
			display: flex;
			width: 87.762%;
			padding: 0;
			margin: $spacing-10 auto 0;
			flex-wrap: wrap;
			justify-content: space-between;

			& > li {
				position: relative;
				width: 48.3%;
				padding: $spacing-6 $spacing-12 $spacing-32 $spacing-8;
				box-sizing: border-box;
				background-color: $background-secondary;
				border-radius: $spacing-10;

				&:nth-of-type(n + 3) { margin-top: $spacing-10; }

				& > em {
					font-family: $lp-family-tertiary;
					display: block;
					font-size: $font-size-20;
					text-align: center;
				}

				& > p { margin: 0; }

				& > svg {
					position: absolute;
					display: none;
					width: 19.7184%;
					bottom: $spacing-4;
					left: 50%;
					transform: translateX(-50%);
				}

				&.is-check {
					background-color: $lp-secondary-color;

					& > svg { display: block; }
				}
			}
		}

		&--button {
			font-family: $family-primary;
			display: block;
			width: 88.0598%;
			margin: $spacing-20 auto 0;
			padding: $spacing-14 0;
			background-color: $button-secondary;
			text-decoration: none;
			color: $text-seconday;
			font-size: $font-size-18;
			text-align: center;
			line-height: $line-height-1;
			border-radius: $spacing-6;
			pointer-events: none;

			&.is-active {
				background-color: $lp-primary-color;
				pointer-events: auto;
			}
		}
	}

	/* 解説・予防法 */
	&__explanation {
		$parent: &;
		padding: $spacing-26 $spacing-20 $spacing-32;
		margin: $spacing-20 $spacing-6 0;
		background: url(~@/assets/img/lp_heatshock2023/bg_top.png) no-repeat 0 0, url(~@/assets/img/lp_heatshock2023/bg_bottom.png) no-repeat 0 100%;
		background-size: 100% auto, 100% auto;

		&--title {
			font-family: $lp-family-tertiary;
			position: relative;
			margin: 0 auto;
			font-size: $font-size-20;
			color: $_black;
			text-align: center;
			line-height: $line-height-1;

			&::before {
				content: '';
				display: block;
				position: absolute;
				height: calc(100% - #{$spacing-3});
				width: 100%;
				top: calc(50% + #{$spacing-3});
				left: 0;
				background-color: $lp-secondary-color;
				border-radius: $spacing-10;
			}
			& > span { position: relative; }
		}

		&--text { margin: $spacing-24 auto 0; }
		&--img { margin: $spacing-20 auto 0; }

		&--inner {
			margin: $spacing-24 auto 0;

			#{$parent}--text,
			#{$parent}--img { margin: $spacing-10 auto 0; }
		}

		&--subtitle {
			font-family: $lp-family-tertiary;
			display: flex;
			margin: 0 auto;
			align-items: center;
			justify-content: center;
			font-size: $font-size-18;
			color: $_black;

			& > svg { width: 8.47458%; }
			& > span {
				display: inline-block;
				padding-left: $spacing-2;
				line-height: $line-height-1;
			}
		}

		&--list {
			margin: $spacing-20 auto 0;

			& > dt {
				font-family: $lp-family-tertiary;
				margin-top: $spacing-22;

				&:first-of-type { margin-top: 0; }
				& > span {
					display: inline-block;
					padding-right: $spacing-4;
					font-weight: bold;
				}
			}

			& > dd {
				margin: 0;

				& > em {
					color: $lp-primary-color;
					font-weight: normal;
				}
			}
		}

		& + #{$parent} { margin-top: $spacing-18; }
		&:last-of-type #{$parent}--inner { margin-top: $spacing-20; }
	}

	/* 危険度 */
	&__risk {
		margin: $spacing-20 auto 0;

		&--title { margin: 0 $spacing-14; }

		&--button {
			display: block;
			width: 86.4554%;
			margin: $spacing-20 auto 0;
			padding: $spacing-10 0;
			background-color: $lp-primary-color;
			box-shadow: 0 3px 0 0 $lp-risk-button-border-color;
			border-radius: $spacing-6;
			color: $text-seconday;
			text-align: center;
			text-decoration: none;
			box-sizing: border-box;

			& > span {
				display: block;
				font-family: $lp-family-secondary;
				line-height: $line-height-1;
				font-size: $font-size-16;

				&.is-large {
					font-family: $lp-family-tertiary;
					font-size: $font-size-24;
					margin-top: $spacing-10;
				}
			}
		}
	}

	/* アプリ誘導 */
	&__app-info {
		margin: $spacing-30 auto 0;
		padding: 0 $spacing-19;
		box-sizing: border-box;

		&--title {
			position: relative;
			margin: 0 auto;
			padding-top: $spacing-100_5;
			background: url(~@/assets/img/lp_heatshock2023/appinfo_title_bg.png) no-repeat 0 0;
			background-size: 100% auto;

			& > span {
				font-family: $lp-family-tertiary;
				position: absolute;
				display: inline-block;
				width: 100%;
				bottom: $spacing-16;
				left: 50%;
				transform: translateX(-50%);
				text-align: center;
				color: $_black;
				font-size: $font-size-18;
			}
		}

		&--text {
			width: 92.8784%;
			margin: $spacing-7 auto 0;

			& > em { font-family: $lp-family-tertiary; }
		}

		&--img { margin: $spacing-10 $spacing-19 0; }

		&--button {
			display: block;
			width: 89.0208%;
			margin: $spacing-10 auto 0;
			padding: $spacing-10 0;
			background-color: $lp-app-info-button-color;
			box-shadow: 0 3px 0 0 $lp-app-info-button-border-color;
			border-radius: $spacing-6;
			color: $text-seconday;
			text-align: center;
			text-decoration: none;
			box-sizing: border-box;

			& > span {
				display: block;
				font-family: $lp-family-secondary;
				line-height: $line-height-1;
				font-size: $font-size-16;

				&.is-large {
					font-family: $lp-family-tertiary;
					font-size: $font-size-24;
					margin-top: $spacing-10;
				}
			}
		}
	}

	/* アウトロ */
	&__outro {
		margin: $spacing-70 0 0 0;

		&--title {
			margin: 0;
			font-family: $lp-family-secondary;
			text-align: center;
			font-weight: normal;
			line-height: $line-height;
		}

		&--img {
			width: 66.6667%;
			margin: $spacing-20 auto 0;
		}

		&--button {
			font-family: $lp-family-secondary;
			display: block;
			border-radius: $spacing-26;
			font-weight: normal;
			text-align: center;
			text-decoration: none;
			box-sizing: border-box;

			&.is-pagetop {
				width: 78.6667%;
				margin: $spacing-20 auto 0;
				padding: $spacing-6 0;
				border: 3px solid $primary;
				color: $primary;
				font-size: $font-size-18;
				background-color: $background-primary;
			}

			&.is-back {
				width: 80%;
				margin: $spacing-14 auto 0;
				padding: $spacing-10 0;
				color: $text-seconday;
				font-size: $font-size-24;
				background-color: $primary;
			}
		}
	}

	// モーダル
	&__modal {
		position: fixed;
		height: 100vh;

		/deep/ .modal__content {
			padding-top: 10vh;
			vertical-align: top;
		}

		&--inner {
			margin: 0 auto;
			width: 347px;
			background-color: $background-primary;

			@media (max-width: (374px)) {
				width: 310px;
			}
		}

		&--body { position: relative; }

		&--button {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;
			height: 35px;
			background: $background-secondary;
			box-sizing: border-box;

			& > a {
				color: $text-primary;
				font-size: $font-size-12;

				@media screen and (min-width: 376px) {
					font-size: 12px;	// タブレットサイズ用（横幅375px以上決め打ち）にpxで指定
				}

				ons-icon {
					font-size: $font-size-16;
					vertical-align: middle;
					margin-right: $spacing-10;

					@media screen and (min-width: 376px) {
						font-size: 16px;	// タブレットサイズ用（横幅375px以上決め打ち）にpxで指定
					}
				}
			}
		}
	}
}

// swiper
.slider {
	padding-top: $spacing-20;

	&__nav {
		.swiper-button-prev,
		.swiper-button-next {
			margin-top: -40px;
			width: 40px;
			height: 40px;

			&:before,
			&:after { display: none; }

			&.swiper-button-disabled { opacity: 0; }
		}

		.swiper-button-prev {
			left: 10px;
			background: url(~@/assets/img/lp_heatshock2023/slider_prev.png) center center / 100% auto no-repeat;
		}

		.swiper-button-next {
			right: 10px;
			background: url(~@/assets/img/lp_heatshock2023/slider_next.png) center center / 100% auto no-repeat;
		}
	}

	&__container {
		margin: 0;

		@media (max-width: (374px)) {
			height: 310px;
		}
	}

	.swiper-wrapper {
		margin: 0;
		padding: 0;
		list-style-type: none;
	}

	.swiper-pagination-bullets {
		padding: $spacing-14 0;
		display: flex;
		align-items: center;
		justify-content: center;

		@media screen and (min-width: 376px) {
			padding: 14px 0;	// タブレットサイズ用（横幅375px以上決め打ち）にpxで指定
		}

		/deep/ .swiper-pagination-bullet {
			margin: 0 $spacing-10;
			width: 5px;
			height: 5px;
			background: $button-secondary;
			opacity: 1;

			@media screen and (min-width: 376px) {
				margin: 0 10px;	// タブレットサイズ用（横幅375px以上決め打ち）にpxで指定
			}
		}

		/deep/ .swiper-pagination-bullet-active { background: $text-primary; }
	}
}

// sprite
.sprite {
	display: block;
	width: 308px;
	height: 308px;
	margin: 0 auto;
	background-image: url(~@/assets/img/lp_heatshock2023/sprite.png);
	background-size: (626px / 2) (10642px / 2);
	background-repeat: no-repeat;

	@media (max-width: (374px)) {
		transform: scale(.89286);
		transform-origin: center center;
	}

	&.app_dl_1 {
		width: (616px / 2);
		height: (616px / 2);
		background-position: (-5px / 2) (-5px / 2);
	}

	&.app_dl_2 {
		width: (616px / 2);
		height: (616px / 2);
		background-position: (-5px / 2) (-631px / 2);
	}

	&.q1_1 {
		width: (616px / 2);
		height: (616px / 2);
		background-position: (-5px / 2) (-1257px / 2);
	}

	&.q1_2 {
		width: (616px / 2);
		height: (616px / 2);
		background-position: (-5px / 2) (-1883px / 2);
	}

	&.q1_3 {
		width: (616px / 2);
		height: (616px / 2);
		background-position: (-5px / 2) (-2509px / 2);
	}

	&.q1_4 {
		width: (616px / 2);
		height: (616px / 2);
		background-position: (-5px / 2) (-3135px / 2);
	}

	&.q1_5 {
		width: (616px / 2);
		height: (616px / 2);
		background-position: (-5px / 2) (-3761px / 2);
	}

	&.q2_1 {
		width: (616px / 2);
		height: (616px / 2);
		background-position: (-5px / 2) (-4387px / 2);
	}

	&.q2_2 {
		width: (616px / 2);
		height: (616px / 2);
		background-position: (-5px / 2) (-5013px / 2);
	}

	&.q2_3 {
		width: (616px / 2);
		height: (616px / 2);
		background-position: (-5px / 2) (-5639px / 2);
	}

	&.q2_4 {
		width: (616px / 2);
		height: (616px / 2);
		background-position: (-5px / 2) (-6265px / 2);
	}

	&.q2_5 {
		width: (616px / 2);
		height: (616px / 2);
		background-position: (-5px / 2) (-6891px / 2);
	}

	&.q3_1 {
		width: (616px / 2);
		height: (616px / 2);
		background-position: (-5px / 2) (-7517px / 2);
	}

	&.q3_2 {
		width: (616px / 2);
		height: (616px / 2);
		background-position: (-5px / 2) (-8143px / 2);
	}

	&.q3_3 {
		width: (616px / 2);
		height: (616px / 2);
		background-position: (-5px / 2) (-8769px / 2);
	}

	&.q3_4 {
		width: (616px / 2);
		height: (616px / 2);
		background-position: (-5px / 2) (-9395px / 2);
	}

	&.q3_5 {
		width: (616px / 2);
		height: (616px / 2);
		background-position: (-5px / 2) (-10021px / 2);
	}
}
</style>
